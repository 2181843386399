<template>
  <div class="tw-mb-4 tw-space-y-4">
    <LeaveDayDetails
      :date="leaveDate"
      :overlaps="overlaps"
      :multiline="false"
      no-overlaps-text="Nobody on leave"
      @show-overlap-leave-details="
        leaveKey => $emit('show-overlap-leave-details', leaveKey)
      "
    />
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import LeaveDayDetails from '@/components/requests/leave/LeaveDayDetails'

export default {
  name: 'HandoverBreakdown',

  components: {
    LeaveDayDetails,
  },

  mixins: [FormatDate],

  props: {
    overlaps: {
      type: Object,
      required: true,
    },
  },

  computed: {
    leaveDate() {
      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        this.overlaps.date
      )
    },
  },
}
</script>
